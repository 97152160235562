import AbstractJQueryElement from "../view/AbstractJQueryElement";
import * as $ from "jquery";

class SearchBar extends AbstractJQueryElement {

    private closeBtn: JQuery;
    constructor(template: JQuery) {
        super(template);
    }

    init() {
        this.closeBtn = this.template.find('.closeBtn');
        this.closeBtn.on('click tap', e => {
            e.preventDefault();
            this.template.removeClass('active');
        });
    }
    toggle() {
        this.template.addClass('active');
    }
}

export default SearchBar;