import * as $ from "jquery";
import "popper.js";
import "bootstrap";
import "bootstrap-datepicker";
import "owl.carousel";
import * as toastr from "toastr";
import * as $clamp from "clamp-js-main";

import Config from "./config/Config";
import NavOverlay from "./view/NavOverlay";
import Nav from "./view/Nav";
import Counter from "./components/Counter";
import ProductDetailPage from "./view/ProductDetailPage";


module App {

    $(function () {
        Config.init();
        createNav();
        createCarousel();
        createModal();
        createCartPage();
        createProductPage();
        createDatepicker();
        $(window).on('resize', e => onWindowResize());
        createTextLineClamp();
        onWindowResize();
    });

    function onWindowResize() {
        resizeEventNewsItem();
        $('.page-container').css({
            'padding-top': $('.main-header').height() + 'px'
        });
        if (navOverlay) navOverlay.resize();
    }

    function resizeEventNewsItem() {
        const sectionTotal = $('.page-container').find('.section-wrapper').length;
        for (let i = 0; i < sectionTotal; i++) {
            const section = $('.page-container').find('.section-wrapper:eq(' + i + ')');
            const listTotal = section.find('.event-news-list').length;

            for (let j = 0; j < listTotal; j++) {
                const list = section.find('.event-news-list:eq(' + j + ')');
                const itemTotal = list.find('.event-news-item').length;
                let infoHeightArr: number[] = [];

                let largestH = 0;
                for (let k = 0; k < itemTotal; k++) {
                    const item = list.find('.event-news-item:eq(' + k + ')');
                    const itemDesc = item.find('.info-desc');
                    infoHeightArr.push(itemDesc.height());
                    largestH = Math.max.apply(Math, infoHeightArr);
                }
                list.find('.event-news-item').each((index, element) => {
                    $(element).find('.info-desc').height(largestH);
                });
            }
        }
    }

    function createCarousel() {
        $('.carousel-banner').owlCarousel({
            items: 1,
            margin: 0,
            center: false,
            loop:true,
            touchDrag: true,
            mouseDrag: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true
        });
        $('.carousel-grid').owlCarousel({
            center: false,
            nav: false,
            dots: false,
            responsive: {
                0: {
                    items: 1,
                    margin: 10,
                    autoplay: true,
                    autoplayTimeout: 5000,
                    touchDrag: true,
                    mouseDrag: true
                },
                768: {
                    items: 3,
                    margin: 30,
                    autoplay: false,
                    touchDrag: false,
                    mouseDrag: false
                }
            }
        });
    }

    function createCartPage() {
        const paymentSelect = $('.cart-page').find('.paymentSelect');
        paymentSelect.on('change', e => {
            const selectContent = $('.cart-page').find('.select-content');
            if ($(e.target).val() == '3') {
                selectContent.addClass('active');
            } else {
                selectContent.removeClass('active');
            }
        });

        const receiptSelect = $('.checkout-page').find('.receiptSelect');
        receiptSelect.on('change', e => {
            changeReceiptContent(receiptSelect.prop('selectedIndex'));
        });
        changeReceiptContent(receiptSelect.prop('selectedIndex'));
        // changeReceiptContent(2);
    }

    function changeReceiptContent(id: number) {
        $('.checkout-page').find('.receipt-content').removeClass('active');
        $('.checkout-page').find('.receipt-content-' + (id + 1)).addClass('active');
    }

    /*
    * ProductPage / ProductDetailPage
    * */
    function createProductPage() {
        const productDetailPage = new ProductDetailPage($('.product-detail-page'));
    }


    /*
    * Datepicker
    * */
    function createDatepicker() {
        $('.datepicker').datepicker({
            startView: 2,
            orientation: "right bottom"
        });
    }


    /*
    * Nav
    * */
    let nav: Nav;
    let navOverlay: NavOverlay;

    function createNav() {

        nav = new Nav($('.mainNav'));
        navOverlay = new NavOverlay($('.overlayNav'));
        $('.toggleBtn').on('click tap', e => {
            let isToggle = navOverlay.isToggle;
            isToggle = !isToggle;
            navOverlay.toggleNav(isToggle);
        });
    }

    function createTextLineClamp() {
        $(".text-ellipsis").each((index, element) => {
            const lineClamp = $(element).data('line-clamp');
            $clamp(element, {clamp: lineClamp, useNativeClamp: true});
        });
    }

    function createModal() {
        if (GetIEVersion() > 0) $('.modal').removeClass('fade');
        $('.modal').on('hidden.bs.modal', e => {
            resetModal();
        });
    }

    function GetIEVersion() {
        let sAgent = window.navigator.userAgent;
        let Idx = sAgent.indexOf("MSIE");
        if (Idx > 0) {
            return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
        } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
            return 11;
        } else {
            return 0; //It is not IE
        }
    }

    export function openModal(name: string = '.modal') {
        $(name).modal('toggle');
    }

    export function closeModal(name: string = '.modal') {
        $(name).find('.closeBtn').trigger('click');
    }

    function resetModal() {
        $('.modal').find('select').prop('selectedIndex', 0);
        $('.modal').find('textarea').val('');
    }

    export function notify(type: string = null, message: string) {
        // @ts-ignore
        toastr.options = {
            "closeButton": false,
            "debug": false,
            "newestOnTop": true,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "300",
            "timeOut": "1500",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
        switch (type) {
            case 'success':
                toastr.success(message);
                break;
            case 'warning':
                toastr.warning(message);
                break;
            case 'error':
                toastr.error(message);
                break;
            default:
                toastr.info(message);
                break;
        }
    }
}

(<any>window).app = App;