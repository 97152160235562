import AbstractJQueryElement from "./AbstractJQueryElement";
import * as $ from "jquery";

class NavOverlay extends AbstractJQueryElement {

    public isToggle: boolean;
    private closeBtn: JQuery;
    private overlay: JQuery;
    private backBtn: JQuery;
    private backBtnText: JQuery;

    private navHead: JQuery;
    private navMask: JQuery;
    private toggleList: JQuery;

    constructor(template: JQuery) {
        super(template);
    }

    resize() {
       /* const $navbar = $('.main-header').find('.navbar');
        this.template.css({
            'height': ($(window).height() - $navbar.outerHeight()) + 'px',
            'top': $navbar.outerHeight() + 'px'
        });
        this.template.find('.nav-wrapper').css({
            'height': ($(window).height() - $navbar.outerHeight()) + 'px'
        })*/
    }

    init() {
        this.isToggle = false;
        this.closeBtn = this.template.find('.closeBtn');
        this.closeBtn.on('click tap', e => {
            this.toggleNav(false);
        });
        this.overlay = this.template.find('.overlay');
        this.overlay.on('click tap', e => {
            this.toggleNav(false);
        });

        this.backBtn = this.template.find('.backBtn');
        this.backBtn.on('click tap', e => {
            this.closeBtn.addClass('active');
            this.backBtnText.html('');
            this.backBtn.removeClass('active');
            this.navMask.removeClass('active');
        });

        this.backBtnText = this.backBtn.find('.text');

        this.navHead = this.template.find('.nav-header');
        this.navMask = this.template.find('.nav-mask');
        this.toggleList = this.template.find('.toggleList');
        this.createNav();
    }

    createNav() {
        const btnTotal = this.navMask.find('.link-toggle').length;
        for (let i = 0; i < btnTotal; i++) {
            const btn = this.toggleList.find('.link-toggle:eq(' + i + ')');
            btn.on('click tap', e => {
                e.preventDefault();
                this.changeActiveList(+$(e.target).data('active-id'));
                this.closeBtn.removeClass('active');
                this.backBtnText.html($(e.currentTarget).find('.text').html());
                this.backBtn.addClass('active');
                this.navMask.addClass('active');
            });
        }
    }

    changeActiveList(id: number) {

        console.log('id', id);
        const listTotal = this.template.find('.active-list').length;
        for (let i = 0; i < listTotal; i++) {
            let activeList = this.template.find('.active-list-' + (i + 1));
            if ((i + 1) == id) {
                activeList.addClass('active');
            } else {
                activeList.removeClass('active');
            }
        }
    }

    toggleNav(toggle: boolean) {
        this.isToggle = toggle;
        if (this.isToggle) {
            this.template.addClass('active');
            $('body,html').css('overflow-y', 'hidden');
            $(window).css('overflow-y', 'hidden');
        } else {
            this.template.removeClass('active');
            $('body,html').css('overflow-y', 'auto');
            $(window).css('overflow-y', 'auto');
        }
    }
}

export default NavOverlay;