import $ = require('jquery');
import * as GSAP from 'gsap';

interface IJQueryView {
    _template: JQuery;
}

abstract class AbstractJQueryElement implements IJQueryView {

    readonly _template: JQuery;

    protected constructor(template: JQuery) {
        this._template = template;
        this.init();
    }

    resize(): void {
    }

    reset(): void {
    }

    init(): void {
    }

    getChild(selector: string): JQuery {
        return this.template.find(selector);
    }

    get template(): JQuery {
        return this._template;
    }
}

export default AbstractJQueryElement;