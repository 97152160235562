export default class Config {

    static __instance: Config;
    static env: string;
    static apiDomain: string;

    constructor() {
        if (Config.__instance) throw new Error('Use Config.instance() instead of new.');
        Config.__instance = this;
    }

    public static instance(): Config {
        if (!Config.__instance) {
            Config.__instance = new Config();
        }
        return Config.__instance;
    }

    public static init(): void {
        if (!Config.__instance) Config.__instance = Config.instance();
        Config.env = process.env.NODE_ENV;
        Config.apiDomain = (Config.env == 'development') ? './' : 'https://pen-hao.com/';
    }
}