import AbstractJQueryElement from "./AbstractJQueryElement";
import SearchBar from "../components/SearchBar";

class Nav extends AbstractJQueryElement {

    private searchBtn: JQuery;
    private searchBar: SearchBar;

    constructor(template: JQuery) {
        super(template);
    }

    init() {
        this.searchBtn = this.template.find('.searchBtn');
        this.searchBar = new SearchBar(this.template.find('.search-bar'));

        this.searchBtn.on('click tap', e => {
            e.preventDefault();
            this.searchBar.toggle();
        });
    }
}

export default Nav;