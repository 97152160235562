import AbstractJQueryElement from "../view/AbstractJQueryElement";

class Counter extends AbstractJQueryElement {

    private plusBtn: JQuery;
    private minusBtn: JQuery;
    private valueInput: JQuery;
    private total: number;

    constructor(template: JQuery) {
        super(template);
    }

    init() {
        this.valueInput = this.template.find('.total');
        this.plusBtn = this.template.find('.plus-btn');
        this.plusBtn.on('click tap', e => {
            e.preventDefault();
            this.total = +this.valueInput.val();
            this.total += 1;
            this.changeValue(this.total);
        });
        this.minusBtn = this.template.find('.minus-btn');
        this.minusBtn.on('click tap', e => {
            e.preventDefault();
            this.total = +this.valueInput.val();
            if (this.total > 1) this.total -= 1;
            this.changeValue(this.total);

        });
    }

    public changeValue(value: number | string) {
        this.valueInput.val(value.toString()).trigger('change');
    }
}

export default Counter;