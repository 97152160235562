import AbstractJQueryElement from "./AbstractJQueryElement";
import Counter from "../components/Counter";
import * as $ from "jquery";


class ProductDetailPage extends AbstractJQueryElement {

    private photoId: number;
    private photoModal: JQuery;
    private photoCarousel: JQuery;
    private productCarousel: JQuery;

    constructor(template: JQuery<HTMLElement>) {
        super(template);
    }

    init(): void {
        super.init();
        const counter = new Counter(this.template.find('.totalCounter'));
        this.template.find('.guideSelect').on('change', e => {
            counter.changeValue(1);
        });
        this.photoId = 0;
        this.productCarousel = this.template.find('#productCarousel');
        this.photoCarousel = $('#photoCarousel');
        this.photoModal = $('#photoModal');
        this.createCarousel();
    }

    private createCarousel(): void {
        this.productCarousel.on('slid.bs.carousel', (event) => {
            this.photoId = event.to;
            this.photoCarousel.carousel(this.photoId);
        });
        this.productCarousel.find('.carousel-item').on('click tap', event => {
            event.preventDefault();
            this.photoCarousel.carousel(this.photoId);
            this.photoModal.modal('toggle');
        });
    }
}

export default ProductDetailPage;